<template>
	<div style="height:100%;">
		<div v-html="text" />
	</div>
</template>
<script>
export default {
	data () {
		return {
			text: "hello world.",
			// nameMapping: $utils.getNameMapping()
		}
	},
	watch: {
		nameMapping: function(v1, v2) {
			console.log(v1);
		}
	},
};
</script>